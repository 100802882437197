import Carousel from 'react-bootstrap/Carousel';

import banner1 from "./banners/01-Mala-Ferramentas.jpg";
import banner2 from "./banners/02-Fritadeira.jpg";
import banner3 from "./banners/03-Moto-Honda-160.jpg";
import banner4 from "./banners/04-Viagem-San-Andrés_Colômbia.jpg";
import banner5 from "./banners/05-SMARTPHONE-SAMSUNG-Z-FLIP.jpg";

import mobile1 from "./banners/01-Mala-Ferramentas-MOBILE.jpg";
import mobile2 from "./banners/02-Fritadeira-MOBILE.jpg";
import mobile3 from "./banners/03-Moto-Honda-160-MOBILE.jpg";
import mobile4 from "./banners/04-Viagem-San-Andrés_Colômbia-MOBILE.jpg";
import mobile5 from "./banners/05-SMARTPHONE-SAMSUNG-Z-FLIP-mobile.jpg";

import useIsMobile from 'app/shared/hooks/useIsMobile';
import { useNavigate } from 'react-router-dom';
import { useMenuContext } from 'app/shared/contexts';
import { useEffect, useState } from 'react';
import ProgressBar from 'react-bootstrap/esm/ProgressBar';

const INTERVAL_TIME = 5000;

export const Carouseltg = () => {
  const Mobile = useIsMobile();
  const [index, setIndex] = useState(0);
  const [paused, setPaused] = useState(false);
  const [progress, setProgress] = useState(0);
  const navigate = useNavigate();
  const { setSelectedItem } = useMenuContext();

  useEffect(() => {
    const timer = setInterval(() => {
      if (!paused) {
        if (Mobile) {
          setProgress(oldProgress => {
            const increment = 100 / (INTERVAL_TIME / 75); // Ajustar para a duração total
            const newProgress = Math.min(oldProgress + increment, 100);
            if (newProgress >= 100) {
              const nextIndex = index === 1 ? 0 : index + 0; // Assumindo 4 slides
              setIndex(nextIndex);
              return 0; // Reinicia o progresso para 0
            }
            return newProgress;
          });
        } else {
          setProgress(oldProgress => {
            const increment = 100 / (INTERVAL_TIME / 50); // Ajustar para a duração total
            const newProgress = Math.min(oldProgress + increment, 100);
            if (newProgress >= 100) {
              const nextIndex = index === 1 ? 0 : index + 0; // Assumindo 4 slides
              setIndex(nextIndex);
              return 0; // Reinicia o progresso para 0
            }
            return newProgress;
          });
        }

      }
    }, 50); // Atualiza a cada 50ms

    return () => clearInterval(timer);
  }, [paused, index, Mobile]);


  useEffect(() => {
    // Reinicia a barra de progresso quando o slide muda
    setProgress(0);
  }, [index]);

  const handleSelect = (selectedIndex: any, e: any) => {
    setIndex(selectedIndex);
    setProgress(100);
  };
  return (

    <div>
      {Mobile ?
        <div>
          <Carousel>
            <Carousel.Item interval={3000}>
              <img
                className="d-block w-100"
                src={mobile1}
                alt="First slide"
              />
            </Carousel.Item>

            <Carousel.Item interval={3000}>
              <img
                className="d-block w-100"
                src={mobile2}
                alt="First slide"
              />
            </Carousel.Item>

            <Carousel.Item interval={3000}>
              <img
                className="d-block w-100"
                src={mobile3}
                alt="First slide"
              />
            </Carousel.Item>

            <Carousel.Item interval={3000}>
              <img
                className="d-block w-100"
                src={mobile4}
                alt="First slide"
              />
            </Carousel.Item>

            <Carousel.Item interval={3000}>
              <img
                className="d-block w-100"
                src={mobile5}
                alt="First slide"
              />
            </Carousel.Item>
          </Carousel>
          <div style={{ overflow: 'hidden', position: 'relative' }}>
            <div className="mx-auto position-relative" style={{ width: '205%', left: '-62%' }}>
              <ProgressBar now={progress} style={{ height: '0.3rem' }} />
            </div>
          </div>
          
        </div>
        :

        <div onMouseEnter={() => setPaused(true)} onMouseLeave={() => setPaused(false)}>
          <Carousel activeIndex={index} onSelect={handleSelect} interval={paused ? null : INTERVAL_TIME}>
            <Carousel.Item interval={3000}
              onClick={() => {
                navigate('/brindes');
                setSelectedItem('brindes');
                window.scrollTo(0, 0);
              }}
              style={{ cursor: 'pointer' }}>
              <img
                className="d-block w-100"
                src={banner1}
                alt="First slide"
              />
            </Carousel.Item>

            <Carousel.Item interval={3000}
              onClick={() => {
                navigate('/brindes');
                setSelectedItem('brindes');
                window.scrollTo(0, 0);
              }}
              style={{ cursor: 'pointer' }}>
              <img
                className="d-block w-100"
                src={banner2}
                alt="First slide"
              />
            </Carousel.Item>

            <Carousel.Item interval={3000}
              onClick={() => {
                navigate('/brindes');
                setSelectedItem('brindes');
                window.scrollTo(0, 0);
              }}
              style={{ cursor: 'pointer' }}>
              <img
                className="d-block w-100"
                src={banner3}
                alt="First slide"
              />
            </Carousel.Item>

            <Carousel.Item interval={3000}
              onClick={() => {
                navigate('/brindes');
                setSelectedItem('brindes');
                window.scrollTo(0, 0);
              }}
              style={{ cursor: 'pointer' }}>
              <img
                className="d-block w-100"
                src={banner4}
                alt="First slide"
              />
            </Carousel.Item>

            <Carousel.Item interval={3000}
              onClick={() => {
                navigate('/brindes');
                setSelectedItem('brindes');
                window.scrollTo(0, 0);
              }}
              style={{ cursor: 'pointer' }}>
              <img
                className="d-block w-100"
                src={banner5}
                alt="First slide"
              />
            </Carousel.Item>
          </Carousel>
          <div style={{ overflow: 'hidden', position: 'relative' }}>
            <div className="mx-auto position-relative" style={{ width: '170%', left: '-45%' }}>
              <ProgressBar now={progress} style={{ height: '0.3rem' }} />
            </div>
          </div>
        </div>
      }
    </div>




  );
}
