import React, { useState, useEffect } from 'react';
import Carousel from 'react-bootstrap/Carousel';
import ProgressBar from 'react-bootstrap/ProgressBar';
import banner1 from "./banners/06-banner-site.jpeg";
import banner2 from "./banners/02-banner-site.jpg";
import banner3 from "./banners/03-banner-site.png";
import banner4 from "./banners/04-banner-site.jpeg";
import banner5 from "./banners/05-banner-site.jpeg";

import mobile1 from "./banners/06-banner-site-mobile.jpeg";
import mobile2 from "./banners/02-banner-site-mobile.jpg";
import mobile3 from "./banners/03-banner-site-mobile.jpg";
import mobile4 from "./banners/04-banner-site-mobile.jpeg";
import mobile5 from "./banners/05-banner-site-mobile.jpeg";

import useIsMobile from 'app/shared/hooks/useIsMobile';
import { useNavigate } from 'react-router-dom';
import { useMenuContext } from 'app/shared/contexts';
import './Carrouselag.css';

const INTERVAL_TIME = 5000;


export const Carouselag = () => {
  const Mobile = useIsMobile();
  const [index, setIndex] = useState(0);
  const [paused, setPaused] = useState(false);
  const [progress, setProgress] = useState(0);
  const navigate = useNavigate();
  const { setSelectedItem } = useMenuContext();
  const { setHiddenMenuVisible } = useMenuContext();
  const { setHiddenMenutgVisible } = useMenuContext();

  useEffect(() => {
    const timer = setInterval(() => {
      if (!paused) {
        if (Mobile) {
          setProgress(oldProgress => {
            const increment = 100 / (INTERVAL_TIME / 75); // Ajustar para a duração total
            const newProgress = Math.min(oldProgress + increment, 100);
            if (newProgress >= 100) {
              const nextIndex = index === 3 ? 0 : index + 1; // Assumindo 4 slides
              setIndex(nextIndex);
              return 0; // Reinicia o progresso para 0
            }
            return newProgress;
          });
        } else {
          setProgress(oldProgress => {
            const increment = 100 / (INTERVAL_TIME / 50); // Ajustar para a duração total
            const newProgress = Math.min(oldProgress + increment, 100);
            if (newProgress >= 100) {
              const nextIndex = index === 3 ? 0 : index + 1; // Assumindo 4 slides
              setIndex(nextIndex);
              return 0; // Reinicia o progresso para 0
            }
            return newProgress;
          });
        }

      }
    }, 50); // Atualiza a cada 50ms

    return () => clearInterval(timer);
  }, [paused, index, Mobile]);


  useEffect(() => {
    // Reinicia a barra de progresso quando o slide muda
    setProgress(0);
  }, [index]);

  const handleSelect = (selectedIndex: any, e: any) => {
    setIndex(selectedIndex);
    setProgress(100);
  };


  return (
    <div>
      {Mobile ?
        <div>
          <Carousel>
            <Carousel.Item interval={3000}
              onClick={() => {
                setSelectedItem('Troque & Ganhe');
                setHiddenMenutgVisible(true);
                navigate('/tvagross');
                window.scrollTo(0, 0);
              }}
              style={{ cursor: 'pointer' }}>
              <img
                className="d-block w-100"
                src={mobile1}
                alt="TV AgRoss"
              />
            </Carousel.Item>

            {/* <Carousel.Item interval={3000}
              onClick={() => {
                setSelectedItem('Troque & Ganhe');
                setHiddenMenutgVisible(true);
                navigate('/troqueeganhe');
                window.scrollTo(0, 0);
              }}
              style={{ cursor: 'pointer' }}>
              <img
                className="d-block w-100"
                src={mobile2}
                alt="Troque e Ganhe"
              />
            </Carousel.Item> */}

            <Carousel.Item interval={3000} onClick={() => {
              navigate('/sementemilho');
              setSelectedItem('Portfólio');
              window.scrollTo(0, 0);
            }}>
              <img
                className="d-block w-100"
                src={mobile3}
                alt="Portfólio"
              />
            </Carousel.Item>

            <Carousel.Item interval={3000}
              onClick={() => {
                navigate('/#');
                setSelectedItem('');
                setHiddenMenuVisible(true);
                window.scrollTo(0, 0);
              }}
              style={{ cursor: 'pointer' }}>
              <a target="_blank" href="https://troqueeganhe.com.br/landingpage/index.php">
                <img
                  className="d-block w-100"
                  src={mobile4}
                  alt="Second slide"
                />
              </a>
            </Carousel.Item>

            <Carousel.Item interval={3000}
              onClick={() => {
                navigate('#');
                setSelectedItem('');
                setHiddenMenuVisible(true);
                window.scrollTo(0, 0);
              }}
              style={{ cursor: 'pointer' }}>
              <a href="https://brevant.agross.com.br" target='_blank'><img
                className="d-block w-100"
                src={mobile5}
                alt="Second slide"
              /></a>
            </Carousel.Item>
          </Carousel>

          <div style={{ overflow: 'hidden', position: 'relative' }}>
            <div className="custom-progress-bar mx-auto position-relative" style={{ width: '205%', left: '-62%' }}>
              <ProgressBar now={progress} style={{ height: '0.3rem' }} />
            </div>
          </div>
        </div>

        :
        <div onMouseEnter={() => setPaused(true)} onMouseLeave={() => setPaused(false)}>

          <Carousel activeIndex={index} onSelect={handleSelect} interval={paused ? null : INTERVAL_TIME}>
            <Carousel.Item interval={3000}
              onClick={() => {
                navigate('/tvagross');
                setSelectedItem('TvAgRoss');
                setHiddenMenuVisible(true);
                window.scrollTo(0, 0);
              }}
              style={{ cursor: 'pointer' }}>
              <img
                className="d-block w-100"
                src={banner1}
                alt="Second slide"
              />
            </Carousel.Item>

            {/* <Carousel.Item interval={3000}
              onClick={() => {
                setSelectedItem('Troque & Ganhe');
                setHiddenMenutgVisible(true);
                navigate('/troqueeganhe');
                window.scrollTo(0, 0);
              }}
              style={{ cursor: 'pointer' }}>
              <img
                className="d-block w-100"
                src={banner2}
                alt="First slide"
              />
            </Carousel.Item> */}
            
            <Carousel.Item interval={3000}
              onClick={() => {
                setSelectedItem('Portfólio');
                setHiddenMenutgVisible(true);
                navigate('/sementemilho');
                window.scrollTo(0, 0);
              }}
              style={{ cursor: 'pointer' }}>
              <img
                className="d-block w-100"
                src={banner3}
                alt="Portfólio"
              />
            </Carousel.Item>

            <Carousel.Item interval={3000}
              onClick={() => {
                setSelectedItem('');
                setHiddenMenutgVisible(true);
                navigate('#');
                window.scrollTo(0, 0);
              }}
              style={{ cursor: 'pointer' }}>
                <a href="https://agross.com.br/cia" target='_blank'>
                  <img
                    className="d-block w-100"
                    src={banner4}
                    alt=""
                  />
                </a>
            </Carousel.Item>

            <Carousel.Item interval={3000}
              onClick={() => {
                setSelectedItem('');
                setHiddenMenutgVisible(true);
                navigate('#');
                window.scrollTo(0, 0);
              }}
              style={{ cursor: 'pointer' }}>
              <a href="https://agross.com.br/brevant" target='_blank'>
                <img
                  className="d-block w-100"
                  src={banner5}
                  alt=""
                />
              </a>
            </Carousel.Item>
          </Carousel>
          <div style={{ overflow: 'hidden', position: 'relative' }}>
            <div className="custom-progress-bar mx-auto position-relative" style={{ width: '170%', left: '-45%' }}>
              <ProgressBar now={progress} style={{ height: '0.3rem' }} />
            </div>
          </div>

        </div>
      }
    </div>
  );
}
